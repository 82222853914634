export const createNotificationSubscriber = async (axiosPrivate, signal, token) => {
    try {
        const response = await axiosPrivate.post('/notification-subscriber',
            {
                token: token,
            },
            {
                signal: signal,
            });

        return response;
    } catch (e) {
        if (!e.response) return { status: 'No Server Response' };

        return e.response;
    }
};

export const deleteNotificationSubscriber = async (axiosPrivate, signal, token) => {
    try {
        const response = await axiosPrivate.delete('/notification-subscriber',
            {
                data: {
                    token: token,
                },
                signal: signal,
            });

        return response;
    } catch (e) {
        if (!e.response) return { status: 'No Server Response' };

        return e.response;
    }
};

export const getNotifications = async (axiosPrivate, signal, page, page_size, type) => {
    try {
        const response = await axiosPrivate.get('/notification',
            {
                params: {
                    page: page,
                    page_size: page_size,
                    type: type,
                },
                signal: signal,
            });

        return response;
    } catch (e) {
        if (!e.response) return { status: 'No Server Response' };

        return e.response;
    }
};

export const readNotifications = async (axiosPrivate, signal, ids) => {
    try {
        const response = await axiosPrivate.post('/notification/read',
            {
                id: ids,
            },
            {
                signal: signal,
            });

        return response;
    } catch (e) {
        if (!e.response) return { status: 'No Server Response' };

        return e.response;
    }
};
