// MUIS
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// STYLES
import useStyles from './backdropLoadingUseStyles';

const BackdropLoading = (props) => {
    const { open } = props;

    const classes = useStyles();

    return (
        <Backdrop
            open={open}
            className={classes.root}
        >
            <CircularProgress
                className={classes.loading}
                thickness={5}
            />
        </Backdrop>
    );
};

export default BackdropLoading;
