// CONSTANTS
// MUI STYLES
import { makeStyles } from '@mui/styles';
import { values } from 'constants/values';

const useStyles = makeStyles((theme) => ({
    appBarRoot: {
        backgroundColor: theme.palette.background.paper,
        ...values.dashboardType === values.dashboardTypePidi && {
            backgroundImage: 'unset',
        },
        width: '100%',
        height: values.privateAppBarHeight,
        zIndex: theme.zIndex.appBar,
        boxShadow: '0 4px 40px rgba(0, 0, 0, 0.08)',
        paddingLeft: 'inherit',
    },
    toolbar: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: 20,
    },
    pageTitle: {
        color: theme.palette.text.primary,
        fontWeight: 600,
        marginRight: 'auto',
    },
    clockText: {
        marginRight: 4,
        textAlign: 'center',
    },
}));

export default useStyles;
