import { createContext, useState } from 'react';
// FULL SCREEN
import { useFullScreenHandle } from 'react-full-screen';

export const LayoutPrivateContext = createContext();

const LayoutPrivateContextProvider = (props) => {
    // APP BAR
    const [ appBarPageTitle, setAppBarPageTitle ] = useState('');
    const [ appBarIsFullScreen, setAppBarIsFullScreen ] = useState(false);
    const handleFullScreen = useFullScreenHandle();

    // DRAWER
    const [ isDrawerExpanded, setIsDrawerExpanded ] = useState(true);

    // NOTIFICATIONS
    const [ mustReloadLayout, setMustReloadLayout ] = useState(true);

    return (
        <LayoutPrivateContext.Provider
            value={{
                // APP BAR
                appBarPageTitle, setAppBarPageTitle,
                appBarIsFullScreen, setAppBarIsFullScreen,
                handleFullScreen,
                // DRAWER
                isDrawerExpanded, setIsDrawerExpanded,
                // NOTIFICATIONS
                mustReloadLayout, setMustReloadLayout,
            }}
        >
            {props.children}
        </LayoutPrivateContext.Provider>
    );
};

export default LayoutPrivateContextProvider;
