// APIS
import axios from 'apis/axiosBlockchainContract';
import { values } from 'constants/values';
// CONSTANTS
import { getPidiAbnormalNotificationBody } from 'utils/dummy';

export const getTrackByHash = async (
    inputSignal,
    inputSearch,
    inputAccessToken,
) => {
    try {
        let headers = {};
        if (values.dashboardType !== values.dashboardTypePidi) {
            headers = { 'X-Authorization': `Bearer ${inputAccessToken}` };
        }

        const response = await axios.get(
            `/track?hash=${inputSearch}`,
            {
                headers,
                signal: inputSignal,
            },

        );

        return response;
    } catch (error) {
        if (!error.response) return { status: 'No Server Response' };
        else return error.response;
    }
};

export const postTrackTriggerAbnormalNotification = async (
    inputSignal,
    inputAccessToken,
    inputType,
) => {
    try {
        let headers = {};
        if (values.dashboardType !== values.dashboardTypePidi) {
            headers = { 'X-Authorization': `Bearer ${inputAccessToken}` };
        }

        const response = await axios.post(
            '/track',
            getPidiAbnormalNotificationBody(inputType),
            {
                headers,
                signal: inputSignal,
            },
        );

        return response;
    } catch (error) {
        if (!error.response) return { status: 'No Server Response' };
        else return error.response;
    }
};
