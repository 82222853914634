// MUI ICONS
import IconNotificationsNoneRounded from '@mui/icons-material/NotificationsNoneRounded';
import IconVisibilityRounded from '@mui/icons-material/VisibilityRounded';
// MUIS
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// CUSTOM COMPONENTS
import CustomTooltipContainer from 'components/Customs/CustomTooltipContainer';
// COMPONENTS
import TooltipIconButton from 'components/TooltipIconButton/TooltipIconButton';
import { notificationType } from 'constants/values';
// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext';
import { LayoutPrivateContext } from 'contexts/LayoutPrivateContext';
import usePublicAxiosPrivate from 'hooks/usePublicAxiosPrivate';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// SERVICES
import { getNotifications, readNotifications } from 'services/public/notification';
// STYLES
import useLayoutStyles from 'styles/layoutPrivate';

import useStyles from './tooltipNotificationListUseStyles';

const MenuNotificationList = () => {

    const navigate = useNavigate();

    const layoutClasses = useLayoutStyles();
    const classes = useStyles();

    const axiosPrivate = usePublicAxiosPrivate();

    const { auth } = useContext(AllPagesContext);
    const { mustReloadLayout, setMustReloadLayout } = useContext(LayoutPrivateContext);

    const [ notificationList, setNotificationList ] = useState([]);
    const [ notificationCount, setNotificationCount ] = useState(0);

    const loadNotificationListData = async (inputIsMounted, inputAbortController) => {
        const resultNotificationList = await getNotifications(
            axiosPrivate,
            inputAbortController.signal,
            1,
            10,
            notificationType.UNREAD,
        );

        if (resultNotificationList.status === 200 && inputIsMounted) {
            const { data, meta } = resultNotificationList.data.data;

            setNotificationList(data);
            setNotificationCount(meta.total);
            setMustReloadLayout(false);
        }
    };

    const handleMarkAsReadButtonClick = async (inputIdList) => {
        const abortController = new AbortController();

        const resultReadNotificationList = await readNotifications(
            axiosPrivate,
            abortController.signal,
            inputIdList,
        );

        if (resultReadNotificationList.status === 200) setMustReloadLayout(true);
    };

    useEffect(() => {
        let isMounted = true;
        const abortController = new AbortController();

        loadNotificationListData(isMounted, abortController);

        return () => {
            isMounted = false;
            abortController.abort();
        };
    }, [auth?.accessToken, mustReloadLayout]);

    const NotificationItem = ({ item }) => {
        const notificationData = item.data.notification;

        return (
            <MenuItem className={classes.notificationItem}>
                <ListItemText
                    className={classes.notificationItemTexts}
                    primary={
                        <Typography className='fontWeight600' noWrap>
                            {notificationData.title}
                        </Typography>
                    }
                    secondary={
                        <Typography noWrap>
                            {notificationData.body}
                        </Typography>
                    }
                />

                {
                    item.read_at ?
                        <Typography variant='body2' color='primary' className='fontWeight600'>
                            Read
                        </Typography>
                        :
                        <TooltipIconButton title='Mark as read' onClick={() => handleMarkAsReadButtonClick([item.id])}>
                            <IconVisibilityRounded/>
                        </TooltipIconButton>
                }
            </MenuItem>
        );
    };

    const NotificationList = () => {
        if (notificationCount < 1) {
            return (
                <>
                    <Typography variant='subtitle1' className={classes.noNotificationText}>
                        No unread notifications.
                    </Typography>

                    <Stack direction='column' justifyContent='flex-end' padding='16px'>
                        <Button variant='contained' onClick={() => navigate('/notification')}>
                            View all notifications
                        </Button>
                    </Stack>
                </>
            );
        }

        return (
            <>
                <Stack rowGap='12px' padding='16px 16px 0px' maxHeight='344px' overflow='auto'>
                    { notificationList.map((item, index) => <NotificationItem key={index} item={item} /> )}
                </Stack>

                <Stack direction='column' justifyContent='flex-end' padding='16px' gap={2}>
                    <Button
                        variant='contained'
                        onClick={() => handleMarkAsReadButtonClick(notificationList.map(item => item.id))}
                    >
                        Mark all as read
                    </Button>

                    <Button variant='contained' onClick={() => navigate('/notification')}>
                        View all notifications
                    </Button>
                </Stack>
            </>
        );
    };

    return (
        <CustomTooltipContainer
            placement='bottom-start'
            leaveDelay={100}
            title={
                <Paper className={classes.tooltipRoot}>
                    <Typography variant='subtitle1' className={classes.tooltipTitle}>
                        Notifications
                    </Typography>

                    <NotificationList />
                </Paper>
            }
        >
            <IconButton className={`${classes.avatarRoot} ${layoutClasses.tooltipIconContainer}`}>
                <Badge badgeContent={notificationCount} className={classes.avatarBadge}>
                    <IconNotificationsNoneRounded fontSize='small'/>
                </Badge>
            </IconButton>
        </CustomTooltipContainer>
    );
};

export default MenuNotificationList;
