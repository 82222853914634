// MUIS
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
// CUSTOM COMPONENTS
import CustomTooltipText from 'components/Customs/CustomTooltipText';
// CONSTANTS
import { values } from 'constants/values';
// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
// I18NEXTS
import { useTranslation } from 'react-i18next';
// UTILS
import {readLanguageFromLocalStorage,
    setLanguageToLocalStorage} from 'utils/localStorage';

// STYLES
import useStyles from './changeLanguageUseStyles';

const ChangeLanguage = (props) => {
    const {
        anchorOrigin,
        transformOrigin,
    } = props;

    const classes = useStyles();

    const { i18n, t } = useTranslation();

    const { setSnackbarObject } = useContext(AllPagesContext);

    const initialSelectedLanguage = readLanguageFromLocalStorage();

    const [ selectedLanguage, setSelectedLanguage ] = useState(initialSelectedLanguage);
    const [ languageMenuAnchor, setLanguageMenuAnchor ] = useState(null);

    const handleLanguageMenuItemClick = (inputItem, inputIndex) => {
        setSelectedLanguage(inputItem);
        i18n.changeLanguage(inputItem.code);
        setLanguageToLocalStorage(JSON.stringify(inputItem));
        setLanguageMenuAnchor(null);

        setSnackbarObject({
            open: true,
            severity: 'success',
            message: t('successChangeLanguage'),
        });
    };

    return (
        <>
            {/* CHANGE LANGUAGE BUTTON */}
            <CustomTooltipText
                title='Change Language'
                placement='bottom'
            >
                <Button
                    className={classes.root}
                    startIcon={
                        <Box
                            loading='lazy'
                            component='img'
                            src={selectedLanguage.flag}
                            alt=''
                            className={classes.flag}
                        />
                    }
                    variant='text'
                    onClick={(event) => setLanguageMenuAnchor(event.currentTarget)}
                >
                    {selectedLanguage.code}
                </Button>
            </CustomTooltipText>

            {/* CHANGE LANGUAGE MENU */}
            <Menu
                anchorEl={languageMenuAnchor}
                open={Boolean(languageMenuAnchor)}
                onClose={() => setLanguageMenuAnchor(null)}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
            >
                {values.languageList.map((item, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => handleLanguageMenuItemClick(item, index)}
                        className={classes.languageItem}
                    >
                        {/* FLAG */}
                        <Box
                            component='img'
                            src={item.flag}
                            alt=''
                            className={classes.flag}
                        />

                        {/* TEXT */}
                        <Typography
                            variant='inherit'
                            className={classes.text}
                        >
                            {item.text}
                        </Typography>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

ChangeLanguage.defaultProps = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
};

ChangeLanguage.propTypes = {
    anchorOrigin: PropTypes.object.isRequired,
    transformOrigin: PropTypes.object.isRequired,
};

export default ChangeLanguage;
