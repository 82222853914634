import axios from 'axios';

const baseURL = process.env.REACT_APP_BLOCKCHAIN_CONTRACT_API_BASE_URL;
const headers = { 'Content-Type': 'application/json' };

export default axios.create({
    baseURL: baseURL,
    headers: headers,
    auth: {
        username: process.env.REACT_APP_BLOCKCHAIN_CONTRACT_API_BASIC_AUTH_USERNAME,
        password: process.env.REACT_APP_BLOCKCHAIN_CONTRACT_API_BASIC_AUTH_PASSWORD,
    },
});
