// MUI ICONS
import IconDarkModeOutlined from '@mui/icons-material/DarkModeOutlined';
import IconLightModeOutlined from '@mui/icons-material/LightModeOutlined';
// COMPONENTS
import TooltipIconButton from 'components/TooltipIconButton/TooltipIconButton';
// CONSTANTS
import { values } from 'constants/values';
// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext';
import { useContext } from 'react';
// UTILS
import { setThemeToLocalStorage } from 'utils/localStorage';

const ToggleTheme = () => {
    const {
        selectedTheme,
        setSelectedTheme,
    } = useContext(AllPagesContext);

    const handleChangeTheme = () => {
        const newTheme = selectedTheme === values.themeLight ? values.themeDark : values.themeLight;
        setSelectedTheme(newTheme);
        setThemeToLocalStorage(newTheme);
    };

    return (
        <TooltipIconButton
            title='Toogle Theme'
            onClick={handleChangeTheme}
        >
            {selectedTheme === values.themeLight
                ? <IconDarkModeOutlined fontSize='small'/>
                : <IconLightModeOutlined fontSize='small'/>}
        </TooltipIconButton>
    );
};

export default ToggleTheme;
