// APIS
import { axiosPrivate } from 'apis/axiosTmmin';
// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext';
// HOOKS
import useTmminRefreshToken from 'hooks/useTmminRefreshToken';
import { useContext, useEffect } from 'react';

const useTmminAxiosPrivate = () => {
    const tmminRefreshToken = useTmminRefreshToken();

    const { auth } = useContext(AllPagesContext);

    useEffect(() => {
        const requestIntercept = axiosPrivate.interceptors.request.use(
            config => {
                if (!config.headers['Authorization']) config.headers['Authorization'] = `Bearer ${auth?.accessToken}`;

                return config;
            },
            (error) => Promise.reject(error),
        );

        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                const previousRequest = error?.config;

                if (error?.response.status === 403 && !previousRequest?.sent) {
                    previousRequest.sent = true;
                    const newAccessToken = await tmminRefreshToken();
                    previousRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

                    return axiosPrivate(previousRequest);
                }

                return Promise.reject(error);
            },
        );

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.request.eject(responseIntercept);
        };
    }, [auth, tmminRefreshToken]);

    return axiosPrivate;
};

export default useTmminAxiosPrivate;
