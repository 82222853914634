// CONSTANTS
// MUI STYLES
import { makeStyles } from '@mui/styles';
import { values } from 'constants/values';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100vh',
        backgroundColor: theme.palette.background.default,
    },
    contentContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        transition: 'padding-left 0.25s ease-in-out',
    },
    mainContent: {
        marginTop: values.privateAppBarHeight,
        flex: 1,
        overflowY: 'auto',
    },
}));

export default useStyles;
