// APIS
import axiosBlockchain from 'apis/axiosBlockchain';

export const publicGetUser = async (token) => {
    try {
        const response = await axiosBlockchain.get('/auth/user', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return response;
    } catch (e) {
        if (!e.response) return { status: 'No Server Response' };

        return e.response;
    }
};

export const publicPostSignInUser = async (inputEmail, inputPassword) => {
    try {
        const response = await axiosBlockchain.post('/auth/login', {
            email: inputEmail,
            password: inputPassword,
        });

        return response;
    } catch (error) {
        if (!error.response) return { status: 'No Server Response' };
        else return error.response;
    }
};

export const publicPostSignUpUser = async (inputName, inputEmail, inputPassword) => {
    try {
        const response = await axiosBlockchain.post('/auth/register', {
            name: inputName,
            email: inputEmail,
            password: inputPassword,
            password_confirmation: inputPassword,
        });

        return response;
    } catch (error) {
        if (!error.response) return { status: 'No Server Response' };

        return error.response;
    }
};

export const publicPostForgotPasswordUser = async (inputEmail) => {
    try {
        const response = await axiosBlockchain.post('/auth/forgot-password', {
            email: inputEmail,
        });

        return response;
    } catch (error) {
        if (!error.response) return { status: 'No Server Response' };

        return error.response;
    }
};

export const publicPostResetPasswordUser = async (inputToken, inputEmail, inputPassword) => {
    try {
        const response = await axiosBlockchain.post('/auth/reset-password', {
            token: inputToken,
            email: inputEmail,
            password: inputPassword,
            password_confirmation: inputPassword,
        });

        return response;
    } catch (error) {
        if (!error.response) return { status: 'No Server Response' };

        return error.response;
    }
};

export const publicPostRefreshToken = async (inputToken) => {
    try {
        const response = await axiosBlockchain.post('/auth/refresh-token', {}, {
            headers: {
                Authorization: `Bearer ${inputToken}`,
            },
        });

        return response;
    } catch (error) {
        if (!error.response) return { status: 'No Server Response' };

        return error.response;
    }
};

export const publicPostSendVerificationEmail = async (inputToken) => {
    try {
        const response = await axiosBlockchain.post('/auth/email/send', {}, {
            headers: {
                Authorization: `Bearer ${inputToken}`,
            },
        });

        return response;
    } catch (error) {
        if (!error.response) return { status: 'No Server Response' };

        return error.response;
    }
};

export const publicPostVerifyEmail = async (id, hash, expires, signature, token) => {
    try {
        const response = await axiosBlockchain.post('/auth/email/verify', {}, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                expires,
                hash,
                id,
                signature,
            },
        });

        return response;
    } catch (error) {
        if (!error.response) return { status: 'No Server Response' };

        return error.response;
    }
};
