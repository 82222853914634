// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext';
import { useContext } from 'react';
// SERVICES
import { publicPostRefreshToken } from 'services/public/user';
// UTILS
import { signOutUser } from 'utils/authentication';

const usePublicRefreshToken = () => {

    const { auth, setAuth } = useContext(AllPagesContext);

    const publicRefreshToken = async () => {
        const responseRefreshToken = await publicPostRefreshToken(
            auth.accessToken,
        );

        if (responseRefreshToken.status === 200) {
            const newToken = responseRefreshToken.data.data.token;

            setAuth(current => {
                return {
                    ...current,
                    accessToken: newToken,
                };
            });

            return newToken;
        }

        signOutUser(setAuth);
    };

    return publicRefreshToken;
};

export default usePublicRefreshToken;
