// CONSTANTS
// import { values } from 'constants/values';
import { lazy } from 'react';

// PAGES
const ForgotPassword = lazy(() => import('pages/ForgotPassword/ForgotPassword'));
const ResetPassword = lazy(() => import('pages/ResetPassword/ResetPassword'));
const SignIn = lazy(() => import('pages/SignIn/SignIn'));
const SignUp = lazy(() => import('pages/SignUp/SignUp'));

export const authenticationRouteList = [
    // SIGN IN
    {
        path: '/sign-in',
        element: <SignIn/>,
        routeType: 'authentication',
    },
    // SIGN UP
    {
        path: '/sign-up',
        element: <SignUp/>,
        routeType: 'authentication',
    },
    // FORGOT PASSWORD
    {
        path: '/forgot-password',
        element: <ForgotPassword/>,
        routeType: 'authentication',
    },
    // RESET PASSWORD
    {
        path: '/reset-password/token=:resetTokenId',
        element: <ResetPassword/>,
        routeType: 'authentication',
    },
];
