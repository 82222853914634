import IconKeyboardArrowDownRounded from '@mui/icons-material/KeyboardArrowDownRounded';
import IconKeyboardArrowUpRounded from '@mui/icons-material/KeyboardArrowUpRounded';
// MUI ICONS
import IconMenuOpen from '@mui/icons-material/MenuOpen';
import IconRemoveRounded from '@mui/icons-material/RemoveRounded';
// MUIS
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Drawer from '@mui/material/Drawer';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// MUI STYLES
import { styled } from '@mui/material/styles';
// COMPONENTS
import TooltipIconButton from 'components/TooltipIconButton/TooltipIconButton';
// CONSTANTS
import { values } from 'constants/values';
// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext';
import { LayoutPrivateContext } from 'contexts/LayoutPrivateContext';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// UTILITIES
import { getCompanyLogo } from 'utils/assets';

// DATA
import drawerNavigationList from './drawerNavigationList';
// STYLES
import useStyles from './drawerUseStyles';

const openedMixin = (theme) => ({
    width: values.drawerWidthOnExpand,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    width: values.drawerWidthOnCollapse,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
});

const StyledDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: values.drawerWidthOnExpand,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const NavigationDrawer = () => {
    const classes = useStyles();

    const navigate = useNavigate();
    let location = useLocation();

    const {
        breakpointType,
        selectedTheme,
    } = useContext(AllPagesContext);
    const {
        isDrawerExpanded,
        setIsDrawerExpanded,
    } = useContext(LayoutPrivateContext);

    const isXsSmMdScreen = breakpointType === 'xs' || breakpointType === 'sm' || breakpointType === 'md';

    const SelectedDrawer = isXsSmMdScreen ? Drawer : StyledDrawer;

    const [ isDrawerHovered, setIsDrawerHovered ] = useState(false);
    const [ expandParent, setExpandParent ] = useState(location.state?.expandParent
        ? location.state.expandParent
        : null);

    const getDrawerOpenState = () => {
        if (!isXsSmMdScreen) return isDrawerExpanded || isDrawerHovered;
        else return isDrawerExpanded;
    };

    const isNavigationActive = (inputPath) => {
        return inputPath.includes(location.pathname);
    };

    const handleParentItemClick = (inputEvent, inputParentItem) => {
        inputEvent.preventDefault();

        if (inputParentItem.type === 'single') {
            navigate(inputParentItem.path, {
                state: {
                    expandParent: null,
                    isDrawerExpanded,
                    lastClicked: 'parent',
                },
            });
        } else if(inputParentItem.type === 'collection' && isDrawerExpanded) {
            if (expandParent === inputParentItem.title) setExpandParent(null);
            else setExpandParent(inputParentItem.title);
        }
    };

    const handleChildrenItemClick = (inputEvent, inputChildrenItem) => {
        inputEvent.preventDefault();

        navigate(inputChildrenItem.path, {
            state: {
                expandParent,
                isDrawerExpanded,
                lastClicked: 'children',
            },
        });
    };

    useEffect(() => {
        if (isXsSmMdScreen) setIsDrawerExpanded(false);
        else setIsDrawerExpanded(true);
    }, [breakpointType]);

    return (
        <SelectedDrawer
            variant={isXsSmMdScreen ? 'temporary' : 'permanent'}
            open={getDrawerOpenState()}
            className={`${classes.root} zoom`}
            onMouseEnter={() => setIsDrawerHovered(true)}
            onMouseLeave={() => setIsDrawerHovered(false)}
        >
            {/* DRAWER HEADER */}
            <Box className={classes.drawerHeader}>
                {/* COMPANY LOGO */}
                <Link
                    href='/dashboard'
                    className={classes.drawerLinkLogo}
                    sx={{ textAlign: !isDrawerExpanded && !isDrawerHovered ? 'center' : 'left' }}
                >
                    <Box
                        component='img'
                        src={(isDrawerExpanded || isDrawerHovered)
                            ? getCompanyLogo('full', selectedTheme)
                            : getCompanyLogo('icon', selectedTheme)
                        }
                        alt=''
                        className={classes.companyLogo}
                    />
                </Link>

                {/* COLLAPSE/EXPAND DRAWER ICON */}
                {(isDrawerExpanded || isDrawerHovered) &&
        <TooltipIconButton
            title='Toggle Drawer'
            onClick={() => setIsDrawerExpanded(current => !current)}
        >
            <IconMenuOpen fontSize='small'/>
        </TooltipIconButton>}
            </Box>

            {/* NAVIGATIONS LIST */}
            <List className={classes.listRoot}>
                {drawerNavigationList.map((parentItem, parentIndex) => (
                    <Fragment key={parentIndex}>
                        {/* PARENT */}
                        <ListItemButton
                            href={parentItem.type === 'single' ? parentItem.path : null}
                            className={isNavigationActive(parentItem.path)
                                ? `${classes.parentItem} ${classes.parentItemActive}`
                                : classes.parentItem}
                            onClick={(event) => handleParentItemClick(event, parentItem)}
                        >
                            {/* ICON */}
                            <ListItemIcon>
                                <parentItem.icon fontSize='small'/>
                            </ListItemIcon>

                            {/* TEXT */}
                            {(isDrawerExpanded || isDrawerHovered) &&
              <ListItemText primary={parentItem.title}/>}

                            {/* EXPAND/COLLAPSE ICON */}
                            {(parentItem.type === 'collection' && isDrawerExpanded) &&
              (expandParent === parentItem.text
                  ? <IconKeyboardArrowUpRounded fontSize='small'/>
                  : <IconKeyboardArrowDownRounded fontSize='small'/>
              )}
                        </ListItemButton>

                        {/* CHILDREN */}
                        {parentItem.type === 'collection' &&
            <Collapse
                in={(parentItem.children && expandParent === parentItem.title)
                && (isDrawerExpanded || isDrawerHovered)}
                timeout='auto'
            >
                <List>
                    {parentItem.children &&
                parentItem.children.map((childrenItem, childrenIndex) => (
                    <ListItemButton
                        key={childrenIndex}
                        href={childrenItem.path}
                        className={isNavigationActive(childrenItem.path)
                            ? `${classes.childrenItem} ${classes.childrenItemActive}`
                            : classes.childrenItem}
                        onClick={(event) => handleChildrenItemClick(event, childrenItem)}
                        disableTouchRipple
                    >
                        {/* ICON */}
                        <ListItemIcon>
                            <IconRemoveRounded/>
                        </ListItemIcon>

                        {/* TEXT */}
                        <ListItemText primary={childrenItem.title}/>
                    </ListItemButton>
                ))}
                </List>
            </Collapse>}
                    </Fragment>
                ))}
            </List>
        </SelectedDrawer>
    );
};

export default NavigationDrawer;
