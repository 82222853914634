import './index.css';
import 'utils/localization';

import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import GlobalStyles from 'components/GlobalStyles/GlobalStyles';
import { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';

if (process.env.REACT_APP_SENTRY_ENABLED === 'true') {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [
            new Sentry.BrowserTracing({
                tracePropagationTargets: ['localhost', process.env.REACT_APP_SENTRY_PROPAGATION_TARGET],
            }),
        ],
        tracesSampleRate: 1.0,
        environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    });
}

// CONTEXTS
const AllPagesContextProvider = lazy(() => import('contexts/AllPagesContext'));

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <ThemeProvider theme={createTheme({})}>
        <StyledEngineProvider injectFirst>
            <AllPagesContextProvider>
                <BrowserRouter>
                    <GlobalStyles/>
                    <App />
                </BrowserRouter>
            </AllPagesContextProvider>
        </StyledEngineProvider>
    </ThemeProvider>,
);
