import { LinearProgress } from '@mui/material';
import BackdropLoading from 'components/BackdropLoading/BackdropLoading';
import AuthenticationRoute from 'components/Routes/AuthenticationRoute';
import PrivateRoute from 'components/Routes/PrivateRoute';
import Snackbar from 'components/Snakbar/Snackbar';
import { AllPagesContext } from 'contexts/AllPagesContext';
import { Suspense, useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import routes from 'routes/routes';
import { getCompanyLogo } from 'utils/assets';
import { getBrowserTheme } from 'utils/theme';

const App = () => {

    const { isBackdropLoadingOpen, snackbarObject, setSnackbarObject } = useContext(AllPagesContext);

    const getRouteComponent = (inputItem) => {
        if (inputItem.routeType === 'authentication') {
            return (
                <AuthenticationRoute>
                    {inputItem.element}
                </AuthenticationRoute>
            );
        }

        if (inputItem.routeType === 'private') {
            return (
                <PrivateRoute>
                    {inputItem.element}
                </PrivateRoute>
            );
        }

        return inputItem.element;
    };

    useEffect(() => {
        const faviconElement = document.getElementById('favicon');
        faviconElement.href = getCompanyLogo('icon', getBrowserTheme());
    }, []);

    return (
        <Suspense fallback={<LinearProgress />}>
            <Routes>
                {routes.map((item, index) => (
                    <Route
                        key={index}
                        path={item.path}
                        element={getRouteComponent(item)}
                    />
                ))}
            </Routes>

            <Snackbar
                open={snackbarObject.open}
                setToast={setSnackbarObject}
                severity={snackbarObject.severity}
                title={snackbarObject.title}
                message={snackbarObject.message}
            />

            <BackdropLoading open={isBackdropLoadingOpen}/>
        </Suspense>
    );
};

export default App;
