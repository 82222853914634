// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext';
import Authentication from 'layouts/Authentication/Authentication';
// LAYOUT
import Private from 'layouts/Private/Private';
import { lazy, useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// UTILS
import { isObjectEmpty } from 'utils/validation';

// CONTEXTS
const LayoutPrivateContextProvider = lazy(() => import('contexts/LayoutPrivateContext'));

function PrivateRoute(props) {
    const { children } = props;

    const { auth } = useContext(AllPagesContext);

    const location = useLocation();

    if (isObjectEmpty(auth)) return <Navigate to='/sign-in' replace />;
    if (!auth.user.email_verified_at && location.pathname !== '/verify-email') return <Navigate to='/verify-email' replace />;
    if (auth.user.email_verified_at && location.pathname === '/verify-email') return <Navigate to='/dashboard' replace />;

    if (location.pathname === '/verify-email') {
        return (
            <Authentication>
                {children}
            </Authentication>
        );
    }

    return (
        <LayoutPrivateContextProvider>
            <Private>
                {children}
            </Private>
        </LayoutPrivateContextProvider>
    );
}

export default PrivateRoute;
