// APIS
import axiosBlockchainCore from 'apis/axiosBlockchainCore';

export const getUserAccountList = async (
    inputSignal,
    inputOffset,
    inputLimit,
    inputOwner,
) => {
    try {
        const response = await axiosBlockchainCore.get(
            `/accounts?offset=${inputOffset}&limit=${inputLimit}&owner=${inputOwner}`,
            { signal: inputSignal },
        );

        return response;
    } catch (error) {
        if (!error.response) return { status: 'No Server Response' };
        else return error.response;
    }
};
