// CONSTANTS
import { values } from 'constants/values';
import { lazy } from 'react';

// CONTEXTS
const PageDashboardContextProvider = lazy(() => import('contexts/PageDashboardContext'));
const PageTraceabilityTmminContextProvider = lazy(() => import('contexts/PageTraceabilityTmminContext'));
const PageTraceabilityDetailTmminContextProvider = lazy(() => import('contexts/PageTraceabilityDetailTmminContext'));

// PAGES
const CarbonFootprint = lazy(() => import('pages/CarbonFootprint/CarbonFootprint'));
const Dashboard = lazy(() => import('pages/Dashboard/Dashboard'));
const Forms = lazy(() => import('pages/Forms/Forms'));
const FormsAddOrEdit = lazy(() => import('pages/FormsAddOrEdit/FormsAddOrEdit'));
const History = lazy(() => import('pages/History/History'));
// const Orders = lazy(() => import('pages/Orders/Orders'))
// const OrdersAddOrEdit = lazy(() => import('pages/OrdersAddOrEdit/OrdersAddOrEdit'))
const Privacy = lazy(() => import('pages/Privacy/Privacy'));
const TraceabilityDetailPidi = lazy(() => import('pages/TraceabilityDetail/Pidi/TraceabilityDetail'));
const TraceabilityDetailTmmin = lazy(() => import('pages/TraceabilityDetail/Tmmin/TraceabilityDetail'));
const TraceabilityPidi = lazy(() => import('pages/Traceability/Pidi/Traceability'));
const TraceabilityTmmin = lazy(() => import('pages/Traceability/Tmmin/Traceability'));
const Trackability = lazy(() => import('pages/Trackability/Trackability'));
const Transactions = lazy(() => import('pages/Transactions/Transactions'));
const TrackHistory = lazy(() => import('pages/TrackHistory/TrackHistory'));

const VerifyEmail = lazy(() => import('pages/VerifyEmail/VerifyEmail'));

const Network = lazy(() => import('pages/Network/Network'));
const NetworkMap = lazy(() => import('pages/NetworkMap/NetworkMap'));
const DistributedLedger = lazy(() => import('pages/DistributedLedger/DistributedLedger'));
const NetworkDetail = lazy(() => import('pages/Network/NetworkDetail/NetworkDetail'));
const EnvironmentDetail = lazy(() => import('pages/Network/EnvironmentDetail/EnvironmentDetail'));
const CreateEnvironment = lazy(() => import('pages/Network/CreateEnvironment/CreateEnvironment'));
const EnvironmentNode = lazy(() => import('pages/Network/EnvironmentNode/EnvironmentNode'));
const CreateNetwork = lazy(() => import('pages/Network/CreateNetwork/CreateNetwork'));
const CreateNode = lazy(() => import('pages/Network/CreateNode/CreateNode'));
const NodeDetail = lazy(() => import('pages/Network/NodeDetail/NodeDetail'));
const Summary = lazy(() => import('pages/Network/Summary/Summary'));

const SmartContractList = lazy(() => import('pages/SmartContract/SmartContractList/SmartContractList'));
const CreateSmartContract = lazy(() => import('pages/SmartContract/CreateSmartContract/CreateSmartContract'));
const SmartContractDetail = lazy(() => import('pages/SmartContract/SmartContractDetail/SmartContractDetail'));

const CreateSmartContractVersion = lazy(() => import('pages/SmartContractVersion/CreateSmartContractVersion/CreateSmartContractVersion'));
const SmartContractVersionDetail = lazy(() => import('pages/SmartContractVersion/SmartContractVersionDetail/SmartContractVersionDetail'));

const Wallet = lazy(() => import('pages/Wallet/Wallet'));

const Notification = lazy(() => import('pages/Notification/Notification'));

const ActivityLog = lazy(() => import('pages/ActivityLog/ActivityLog'));

// FORMS ITEMS PAGES
const formsItemsPageList = [
    // LIST OF ITEMS
    values.dashboardType === values.dashboardTypePublic && {
        path: '/forms/items',
        element: <Forms/>,
        routeType: 'private',
    },
    // ADD AN ITEM
    values.dashboardType === values.dashboardTypePublic && {
        path: '/forms/items/add',
        element: <FormsAddOrEdit/>,
        routeType: 'private',
    },
    // EDIT AN ITEM
    values.dashboardType === values.dashboardTypePublic && {
        path: '/forms/items/edit/:id',
        element: <FormsAddOrEdit/>,
        routeType: 'private',
    },
];

// FORMS ACTORS PAGES
const formsActorsPageList = [
    // LIST OF ACTORS
    values.dashboardType === values.dashboardTypePublic && {
        path: '/forms/actors',
        element: <Forms/>,
        routeType: 'private',
    },
    // ADD AN ACTOR
    values.dashboardType === values.dashboardTypePublic && {
        path: '/forms/actors/add',
        element: <FormsAddOrEdit/>,
        routeType: 'private',
    },
    // EDIT AN ACTOR
    values.dashboardType === values.dashboardTypePublic && {
        path: '/forms/actors/edit/:id',
        element: <FormsAddOrEdit/>,
        routeType: 'private',
    },
];

// FORMS TOUCH POINTS PAGES
const formsTouchPointsPageList = [
    // LIST OF TOUCH POINTS
    values.dashboardType === values.dashboardTypePublic && {
        path: '/forms/touch-points',
        element: <Forms/>,
        routeType: 'private',
    },
    // ADD A TOUCH POINT
    values.dashboardType === values.dashboardTypePublic && {
        path: '/forms/touch-points/add',
        element: <FormsAddOrEdit/>,
        routeType: 'private',
    },
    // EDIT A TOUCH POINT
    values.dashboardType === values.dashboardTypePublic && {
        path: '/forms/touch-points/edit/:id',
        element: <FormsAddOrEdit/>,
        routeType: 'private',
    },
];

// FORM WORKFLOWS PAGES
const formsWorkflowsPageList = [
    // LIST OF WORKFLOWS
    values.dashboardType === values.dashboardTypePublic && {
        path: '/forms/workflows',
        element: <Forms/>,
        routeType: 'private',
    },
    // ADD A WORKFLOW
    values.dashboardType === values.dashboardTypePublic && {
        path: '/forms/workflows/add',
        element: <FormsAddOrEdit/>,
        routeType: 'private',
    },
    // EDIT A WORKFLOW
    values.dashboardType === values.dashboardTypePublic && {
        path: '/forms/workflows/edit/:id',
        element: <FormsAddOrEdit/>,
        routeType: 'private',
    },
];

// TRACEABILITY PAGES
const traceabilityPageList = [
    // KEDAIREKA, PIDI, AND PUBLIC TRACEABILITY
    (values.dashboardType === values.dashboardTypePublic ||
  values.dashboardType === values.dashboardTypeKedaireka ||
  values.dashboardType === values.dashboardTypePidi) && {
        path: '/traceability',
        element: <TraceabilityPidi/>,
        routeType: 'private',
    },
    // KEDAIREKA, PIDI, AND PUBLIC DETAIL TRACEABILITY
    (values.dashboardType === values.dashboardTypePublic ||
  values.dashboardType === values.dashboardTypeKedaireka ||
  values.dashboardType === values.dashboardTypePidi) && {
        path: '/traceability/detail',
        element: <TraceabilityDetailPidi/>,
        routeType: 'private',
    },
    // TMMIN TRACEABILITY
    (values.dashboardType === values.dashboardTypePublic ||
  values.dashboardType === values.dashboardTypeKedaireka ||
  values.dashboardType === values.dashboardTypeTmmin) && {
        path: '/traceability',
        element: (
            <PageTraceabilityTmminContextProvider>
                <TraceabilityTmmin/>
            </PageTraceabilityTmminContextProvider>
        ),
        routeType: 'private',
    },
    // TMMIN DETAIL TRACEABILITY
    (values.dashboardType === values.dashboardTypePublic ||
  values.dashboardType === values.dashboardTypeKedaireka ||
  values.dashboardType === values.dashboardTypeTmmin) && {
        path: '/traceability/detail',
        element: (
            <PageTraceabilityDetailTmminContextProvider>
                <TraceabilityDetailTmmin/>
            </PageTraceabilityDetailTmminContextProvider>
        ),
        routeType: 'private',
    },
];

// ORDERS
// const ordersPageList = [
//   // ORDERS
//   values.dashboardType === values.dashboardTypePidi &&
//   {
//     path: '/orders',
//     element: <Orders/>,
//     routeType: 'private',
//   },
//   // CREATE AN ORDER
//   values.dashboardType === values.dashboardTypePidi &&
//   {
//     path: '/orders/create',
//     element: <OrdersAddOrEdit/>,
//     routeType: 'private',
//   },
// ]

export const privateRouteList = [
    // VERIFY EMAIL
    {
        path: '/verify-email',
        element: <VerifyEmail />,
        routeType: 'private',
    },
    {
        path: '/network/summary',
        element: <Summary />,
        routeType: 'private',
    },
    {
        path: '/network-map',
        element: <NetworkMap />,
        routeType: 'private',
    },
    {
        path: '/distributed-ledger',
        element: <DistributedLedger />,
        routeType: 'private',
    },
    {
        path: '/network/create',
        element: <CreateNetwork />,
        routeType: 'private',
    },
    {
        path: '/network/detail/:networkId',
        element: <NetworkDetail />,
        routeType: 'private',
    },
    {
        path: '/network/environment/:environmentId',
        element: <EnvironmentDetail />,
        routeType: 'private',
    },
    {
        path: '/network/environment/create/:networkId',
        element: <CreateEnvironment />,
        routeType: 'private',
    },
    {
        path: '/network/environment/node/create/:environmentId',
        element: <CreateNode />,
        routeType: 'private',
    },
    {
        path: '/smart-contract',
        element: <SmartContractList />,
        routeType: 'private',
    },
    {
        path: '/wallet',
        element: <Wallet />,
        routeType: 'private',
    },
    {
        path: '/smart-contract/create',
        element: <CreateSmartContract />,
        routeType: 'private',
    },
    {
        path: '/smart-contract/:smartContractId',
        element: <SmartContractDetail />,
        routeType: 'private',
    },
    {
        path: '/smart-contract/:smartContractId/create',
        element: <CreateSmartContractVersion />,
        routeType: 'private',
    },
    {
        path: '/smart-contract-version/:smartContractVersionId',
        element: <SmartContractVersionDetail />,
        routeType: 'private',
    },
    // {
    //     path: '/network/environmentNode',
    //     element: <EnvironmentNode/>,
    //     routeType: 'private',
    // },
    // {
    //     path: '/network/nodeDetail',
    //     element: <NodeDetail/>,
    //     routeType: 'private',
    // },
    // DASHBOARD
    {
        path: '/dashboard',
        element: (
            <PageDashboardContextProvider>
                <Dashboard />
            </PageDashboardContextProvider>
        ),
        routeType: 'private',
    },
    // CARBON FOOTPRINT
    (values.dashboardType === values.dashboardTypePublic || values.dashboardType === values.dashboardTypeKedaireka) && {
        path: '/carbon-footprint',
        element: <CarbonFootprint />,
        routeType: 'private',
    },
    // TRACEABILITY
    ...traceabilityPageList,
    // PRIVACY
    (values.dashboardType === values.dashboardTypePublic || values.dashboardType === values.dashboardTypeTmmin) && {
        path: '/privacy',
        element: <Privacy />,
        routeType: 'private',
    },
    // TRACKABILITY
    (values.dashboardType === values.dashboardTypePublic || values.dashboardType === values.dashboardTypeKedaireka) && {
        path: '/trackability',
        element: <Trackability />,
        routeType: 'private',
    },
    // HISTORY
    values.dashboardType !== values.dashboardTypePidi && {
        path: '/history',
        element: <History />,
        routeType: 'private',
    },
    // FORMS - ITEMS
    ...formsItemsPageList,
    // FORMS - ACTORS
    ...formsActorsPageList,
    // FORMS - TOUCH POINTS
    ...formsTouchPointsPageList,
    // FORMS - WORKFLOWS
    ...formsWorkflowsPageList,
    // TRANSACTIONS
    values.dashboardType === values.dashboardTypePublic && {
        path: '/transactions',
        element: <Transactions />,
        routeType: 'private',
    },
    // ORDERS
    // ...ordersPageList,
    // TRACK HISTORY
    {
        path: '/track-history',
        element: <TrackHistory />,
        routeType: 'private',
    },
    {
        path: '/notification',
        element: <Notification />,
        routeType: 'private',
    },
    {
        path: '/activity-log',
        element: <ActivityLog />,
        routeType: 'private',
    },
];
