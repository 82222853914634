// CONSTANTS
import { values } from 'constants/values';

export const getBrowserTheme = () => {
    if (window.matchMedia) {
        if (window.matchMedia('(prefers-color-scheme: dark)').matches) return values.themeDark;

        return values.themeLight;
    }

    return values.themeLight;
};
