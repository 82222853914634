// MUIS
import { alpha } from '@mui/material/styles';
// MUI STYLES
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    // BUTTON
    buttonAdd: {
        width: 'fit-content',
        alignSelf: 'flex-end',
    },
    buttonDownload: {
        minWidth: 'fit-content',
        height: 'fit-content',
    },
    traceButton: {
        alignSelf: 'flex-end',
        width: 160,
    },
    // CARD
    card: {
        minHeight: 465,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardUnset: {
        minHeight: 'unset',
        height: 'unset',
    },
    cardItemTitle: {
        fontWeight: 600,
        color: theme.palette.text.primary,
        marginBottom: 16,
    },
    cardItemTitleDivider: {
        marginBottom: 20,
    },
    iconBlockchain: {
        width: 32,
    },
    // FILTER
    filterItem: {
        display: 'flex',
        flexDirection: 'column',
    },
    filterLabel: {
        marginBottom: 4,
        marginLeft: 12,
        color: theme.palette.text.primary,
    },
    filterInput: {
        backgroundColor: theme.palette.background.paper,
        height: 44,
        width: '100%',
        borderRadius: 8,
        boxShadow: `0px 8px 24px ${alpha('#000000', 0.04)}`,
    },
    // FORM
    formLabel: {
        marginBottom: 4,
        color: theme.palette.text.primary,
    },
    formInput: {
        height: 44,
        width: '100%',
        borderRadius: 8,
    },
    // PAGE
    pageOverflow: {
        width: '100%',
        position: 'relative',
        padding: 24,
        overflow: 'auto',
        [theme.breakpoints.only('xs')]: {
            padding: 20,
        },
    },
    pageNonOverflow: {
        width: '100%',
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
        padding: 24,
        [theme.breakpoints.only('xs')]: {
            padding: 20,
        },
    },
    // TIMLINE
    timeline: {
        padding: 0,
        margin: 0,
    },
    timelineOppositeContent: {
        flex: 0,
        padding: 0,
    },
    timelineDotParentPassed: {
        backgroundColor: theme.palette.primary.main,
        boxShadow: 'unset',
        color: 'white',
    },
    timelineDotParentNotPassed: {
        backgroundColor: alpha(theme.palette.grey[500], 0.16),
        boxShadow: 'unset',
        color: alpha(theme.palette.grey[500], 1),
    },
    timelineDotChildrenPassed: {
        backgroundColor: alpha(theme.palette.primary.main, 0.16),
        boxShadow: 'unset',
        color: alpha(theme.palette.primary.main, 0.4),
    },
    timelineDotChildrenNotPassed: {
        backgroundColor: alpha(theme.palette.grey[500], 0.16),
        boxShadow: 'unset',
        color: alpha(theme.palette.grey[500], 0.4),
    },
    timelineConnectorPassed: {
        backgroundColor: 'unset',
        border: `1px dashed ${theme.palette.primary.main}`,
    },
    timelineConnectorNotPassed: {
        backgroundColor: 'unset',
        border: `1px dashed ${theme.palette.grey[500]}`,
    },
    timelineTitleParent: {
        fontSize: 18,
        fontWeight: 600,
        marginTop: 8,
    },
    timelineTitleChildrenNotPassed: {
        fontWeight: 600,
        marginTop: 8,
        color: theme.palette.text.secondary,
    },
    timelineContentButtonChildren: {
        padding: 0,
        color: 'unset',
        marginTop: 8,
        marginBottom: 12,
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    timelineContentAttributeItemKey: {
        width: 200,
        marginRight: 8,
    },
    timelineContentAttributeItemValue: {
        flex: 1,
        fontWeight: 600,
        wordBreak: 'break-word',
    },
    // TOOLTIP
    tooltipIconContainer: {
        color: theme.palette.action.active,
        '&:hover': {
            backgroundColor: theme.palette.additional.iconButtonHover,
        },
    },
}));

export default useStyles;
