import { axiosPrivate } from 'apis/axiosBlockchain';
import { AllPagesContext } from 'contexts/AllPagesContext';
import usePublicRefreshToken from 'hooks/usePublicRefreshToken';
import { useContext, useEffect } from 'react';

const usePublicAxiosPrivate = () => {

    const publicRefreshToken = usePublicRefreshToken();

    const { auth } = useContext(AllPagesContext);

    useEffect(() => {
        const requestInterceptor = axiosPrivate.interceptors.request.use(
            config => {
                if (!config.headers['Authorization']) config.headers['Authorization'] = `Bearer ${auth?.accessToken}`;

                return config;
            },
            (error) => Promise.reject(error),
        );

        const responseInterceptor = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                const previousRequest = error?.config;

                if ((error?.response.status === 401) && !previousRequest?.sent) {
                    previousRequest.sent = true;
                    const newAccessToken = await publicRefreshToken();
                    previousRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

                    return axiosPrivate(previousRequest);
                }

                return Promise.reject(error);
            },
        );

        return () => {
            axiosPrivate.interceptors.request.eject(requestInterceptor);
            axiosPrivate.interceptors.request.eject(responseInterceptor);
        };
    }, [auth, publicRefreshToken]);

    return axiosPrivate;
};

export default usePublicAxiosPrivate;
