const english = {
    translation: {
        // GENERAL VIEW
        username: 'Username',
        inputYourUsername: 'Input your username here',
        name: 'Name',
        inputYourName: 'Input your name here',
        password: 'Password',
        inputYourPassword: 'Input your password here',
        role: 'Role',
        inputSelectRole: 'Select one role here',
        admin: 'Admin',
        supplier: 'Supplier',
        manufacture: 'Manufacture',
        distributor: 'Distributor',
        government: 'Government',
        emailAddress: 'Email address',
        inputYourEmailAddress: 'Input your email address here',
        confirmPassword: 'Confirm password',
        inputConfirmPassword: 'Repeat your password here',
        newPassword: 'New password',
        inputNewPassword: 'Input your new password here',
        login: 'Login',
        backTo: 'Back to',
        showPassword: 'Show password',
        hidePassword: 'Hide password',
        // GENERAL FEEDBACK
        emptyFieldValidation: 'Please fill all fields',
        noServerResponse: 'No Server Response',
        passwordAtLeast: 'Password must be at least 8 characters',
        passwordDoenstMatchConfirm: "Password and confirm password don't match",
        copiedToClipboard: 'Copied to clipboard',
        successChangeLanguage: 'Successfully changing language',
        // SIGN IN PAGE
        signInPageTitle: 'Sign in to an account',
        signInPageCaption: 'Enter your username and password to login',
        signInForgotPassword: 'Forgot Password?',
        signInDontHaveAccount: "Don't have an account?",
        signInCreateAccount: 'Create a new account',
        signInSuccess: 'Successfully logging in, welcome',
        signInErrorUnregisteredUsername: 'Username is not registered',
        signInErrorWrongPassword: 'Wrong password',
        signInErrorBadCredentials: 'Wrong username or password',
        signInExpiredSubscription: 'Expired subsctiption',
        // SIGN UP PAGE
        signUpPageTitle: 'Create your account',
        signUpPageCaption: 'Enter your info to create an account',
        signUpButton: 'Create account',
        signUpAlreadyHaveAccount: 'Already have an account?',
        signUpSuccess: 'Successfully creating a new account',
        signUpErrorTakenUsername: 'Username is already taken',
        signUpErrorTakenEmail: 'Email is already used',
        // FORGOT PASSWORD PAGE
        forgotPasswordTitle: 'Forgot password',
        forgotPasswordCaption: 'Please enter your email and we’ll send you the instructions to reset your password',
        forgotPasswordButton: 'Send Reset Instruction',
        forgotPasswordSuccess: 'Reset password instruction was sent to your email',
        forgotPasswordUnregisteredEmail: 'The email is unregistered',
        // RESET PASSWORD PAGE
        resetPasswordTitle: 'Reset your password',
        resetPasswordCaption: 'Enter your new password to change the old password',
        resetPasswordChangeEmail: 'Change email',
        resetPasswordButton: 'Reset Password',
        resetPasswordSuccess: 'Your password has been reseted',
        resetPasswordExpiredToken: 'Token is expired, please send a new request',
        resetPasswordInvalidToken: 'Token is invalid, please send a new request',
        resetPasswordThrottled: 'Too many reset attempts. Please try again later',
        // VERIFY EMAIL PAGE
        verifyEmailTitle: 'Verify your email',
        verifyEmailCaption: 'Click the button below to send a verification instruction to your email',
        verifyEmailButton: 'Send Verification Instruction',
        verifyEmailSent: 'Verification instruction was sent to your email',
        verifyEmailAlreadyVerified: 'Your email is already verified',
        verifyEmailLoading: `Please wait while we're verifying your email`,
        verifyEmailSuccessTitle: 'Your email has been verified',
        verifyEmailContinue: 'Go to dashboard',
    },
};

export default english;
