// CONSTANTS
// MUIS
import { alpha } from '@mui/material/styles';
// MUI STYLES
import { makeStyles } from '@mui/styles';
import { values } from 'constants/values';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'block',
        '& .MuiDrawer-paper': {
            border: 'none',
            boxShadow: '0 0 20px rgba(0, 0, 0, 0.04)',
            zIndex: theme.zIndex.drawer,
            ...values.dashboardType === values.dashboardTypePidi && {
                backgroundImage: 'unset',
            },
        },
        [theme.breakpoints.between('sm', 'lg')]: {
            '& .MuiDrawer-paper': {
                width: values.drawerWidthOnExpand,
            },
        },
        [theme.breakpoints.only('xs')]: {
            '& .MuiDrawer-paper': {
                width: '100%',
            },
        },
    },
    drawerHeader: {
        width: '100%',
        height: 80,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 20,
        boxShadow: '-10px 0 20px rgba(0, 0, 0, 0.08)',
    },
    drawerLinkLogo: {
        flex: 1,
        height: '100%',
    },
    companyLogo: {
        maxWidth: '100%',
        height: '100%',
    },
    listRoot: {
        padding: 20,
    },
    parentItem: {
        padding: 12,
        paddingLeft: 16,
        borderRadius: 12,
        height: 44,
        color: theme.palette.text.primary,
        '& .MuiListItemIcon-root': {
            minWidth: 'unset',
            marginRight: 16,
        },
        '& .MuiSvgIcon-root': {
            color: theme.palette.text.primary,
        },
        '& .MuiTypography-root': {
            fontWeight: 600,
            color: theme.palette.text.primary,
        },
        '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.2),
        },
    },
    parentItemActive: {
        backgroundColor: alpha(theme.palette.primary.main, 0.2),
        '& .MuiSvgIcon-root': {
            color: theme.palette.primary.main,
        },
        '& .MuiTypography-root': {
            fontWeight: 600,
            color: theme.palette.primary.main,
        },
    },
    childrenItem: {
        color: theme.palette.text.secondary,
        padding: '4px 16px',
        '& .MuiListItemIcon-root': {
            minWidth: 'unset',
            marginRight: 20,
            marginLeft: 2,
        },
        '& .MuiSvgIcon-root': {
            width: 16,
            color: theme.palette.text.secondary,
        },
        '& .MuiTypography-root': {
            fontWeight: '600 !important',
            color: theme.palette.text.secondary,
        },
        '&:hover': {
            backgroundColor: 'unset',
            color: `${theme.palette.primary.main} !important`,
            fontWeight: 600,
            '& .MuiSvgIcon-root, & .MuiTypography-root': {
                color: theme.palette.primary.main,
            },
        },
    },
    childrenItemActive: {
        '& .MuiSvgIcon-root, & .MuiTypography-root': {
            color: theme.palette.primary.main,
        },
    },
}));

export default useStyles;
