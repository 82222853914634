import  {createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { colors, colorsDark, colorsLight } from 'constants/colors';
import themeInitial from 'constants/initialTheme';
import { values } from 'constants/values';
import { createContext, useEffect, useState } from 'react';
import { readThemeFromLocalStorage, readUserProfileFromLocalStorage } from 'utils/localStorage';

export const AllPagesContext = createContext();

const AllPagesContextProvider = (props) => {
    // AUTH
    const [ auth, setAuth ] = useState(readUserProfileFromLocalStorage());

    // BACKDROP LOADING
    const [ isBackdropLoadingOpen, setIsBackdropLoadingOpen ] = useState(false);

    // BREAKPOINT
    const isXsScreen = useMediaQuery((theme) => theme.breakpoints.only('xs'));
    const isSmScreen = useMediaQuery((theme) => theme.breakpoints.only('sm'));
    const isMdScreen = useMediaQuery((theme) => theme.breakpoints.only('md'));
    const isLgScreen = useMediaQuery((theme) => theme.breakpoints.only('lg'));
    const isXlScreen = useMediaQuery((theme) => theme.breakpoints.only('xl'));

    let breakpointType;
    isXsScreen && (breakpointType = 'xs');
    isSmScreen && (breakpointType = 'sm');
    isMdScreen && (breakpointType = 'md');
    isLgScreen && (breakpointType = 'lg');
    isXlScreen && (breakpointType = 'xl');

    // SNACKBAR
    const [ snackbarObject, setSnackbarObject ] = useState(values.initialSnackbarObject);

    // THEME
    let initialTheme = readThemeFromLocalStorage();
    if (values.dashboardType === values.dashboardTypePidi || values.dashboardType === values.dashboardTypeKedaireka) initialTheme = 'dark';
    const [ selectedTheme, setSelectedTheme ] = useState(initialTheme);
    const [ themeObject, setThemeObject ] = useState(themeInitial);
    const isLightTheme = selectedTheme === values.themeLight;

    console.log('breakpointType: ', breakpointType);

    useEffect(() => {
        setThemeObject((current) => {
            return createTheme(current, {
                palette: {
                    mode: selectedTheme,
                    primary: colors.primary,
                    secondary: colors.secondary,
                    error: colors.error,
                    success: colors.success,
                    warning: colors.warning,
                    info: colors.info,
                    text: isLightTheme ? colorsLight.text : colorsDark.text,
                    divider: isLightTheme ? colorsLight.divider : colorsDark.divider,
                    background: isLightTheme ? colorsLight.background : colorsDark.background,
                    action: isLightTheme ? colorsLight.action : colorsDark.action,
                    additional: isLightTheme ? colorsLight.additional : colorsDark.additional,
                },
            });
        });
    }, [selectedTheme]);

    return (
        <AllPagesContext.Provider
            value={{
                // AUTH
                auth, setAuth,
                // BACKDROP LOADING
                isBackdropLoadingOpen, setIsBackdropLoadingOpen,
                // BREAKPOINT
                breakpointType,
                // SNACKBAR
                snackbarObject, setSnackbarObject,
                // THEME
                selectedTheme, setSelectedTheme,
            }}
        >
            <ThemeProvider theme={themeObject}>
                {props.children}
            </ThemeProvider>
        </AllPagesContext.Provider>
    );
};

export default AllPagesContextProvider;
