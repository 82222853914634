// CONSTANTS
// MUI ICONS
// import IconChangeCircleOutlined from '@mui/icons-material/ChangeCircleOutlined';
import { DeviceHub } from '@mui/icons-material';
import IconWallet from '@mui/icons-material/AccountBalanceWalletOutlined';
import IconArticleOutlined from '@mui/icons-material/ArticleOutlined';
import IconEnergySavingsLeafOutlined from '@mui/icons-material/EnergySavingsLeafOutlined';
import IconHistoryOutlined from '@mui/icons-material/HistoryOutlined';
import IconHomeOutlined from '@mui/icons-material/HomeOutlined';
import IconHubOutlinedIcon from '@mui/icons-material/HubOutlined';
// import IconReceiptLongOutlined from '@mui/icons-material/ReceiptLongOutlined'
// import IconRouteOutlined from '@mui/icons-material/RouteOutlined';
// import IconSecurityOutlined from '@mui/icons-material/SecurityOutlined';
import IconNetworkShare from '@mui/icons-material/Share';
import IconTextSnippetOutlined from '@mui/icons-material/TextSnippetOutlined';
import IconTimelineOutlined from '@mui/icons-material/TimelineOutlined';
import { values } from 'constants/values';

let drawerNavigationList = [
    {
        type: 'single',
        icon: IconHomeOutlined,
        title: 'Dashboard',
        path: '/dashboard',
    },
    (values.dashboardType === values.dashboardTypePublic || values.dashboardType === values.dashboardTypeKedaireka) && {
        type: 'single',
        icon: IconEnergySavingsLeafOutlined,
        title: 'Carbon Footprint',
        path: '/carbon-footprint',
    },
    values.dashboardType !== values.dashboardTypeTmmin && {
        type: 'single',
        icon: IconTimelineOutlined,
        title: 'Traceability',
        path: '/traceability',
    },
    [values.dashboardTypeTmmin, values.dashboardTypePublic].includes(values.dashboardType) && {
        type: 'single',
        icon: IconTimelineOutlined,
        title: 'Track History',
        path: '/track-history',
    },
    [values.dashboardTypeTmmin, values.dashboardTypePublic].includes(values.dashboardType) && {
        type: 'collection',
        title: 'Network',
        icon: IconNetworkShare,
        path: '/network',
        children: [
            {
                path: '/network/summary',
                title: 'Summary',
            },
            {
                path: '/network/create',
                title: 'Create Network',
            },
            // {
            //     path: '/network/environment',
            //     title: 'Environment',
            // },
            // {
            //     path: '/network/environmentDetail',
            //     title: 'Environment Details',
            // },
            // {
            //     path: '/network/environmentNode',
            //     title: 'Environment Node',
            // },
            // {
            //     path: '/network/nodeDetail',
            //     title: 'Node Details',
            // },
        ],
    },
    [values.dashboardTypeTmmin, values.dashboardTypePublic].includes(values.dashboardType) && {
        type: 'single',
        icon: DeviceHub,
        title: 'Network Map',
        path: '/network-map',
    },
    [values.dashboardTypeTmmin, values.dashboardTypePublic].includes(values.dashboardType) && {
        type: 'single',
        icon: IconHubOutlinedIcon,
        title: 'Distributed Ledger',
        path: '/distributed-ledger',
    },
    [values.dashboardTypeTmmin, values.dashboardTypePublic].includes(values.dashboardType) && {
        type: 'single',
        icon: IconWallet,
        title: 'Wallet',
        path: '/wallet',
    },
    [values.dashboardTypeTmmin, values.dashboardTypePublic].includes(values.dashboardType) && {
        type: 'single',
        icon: IconArticleOutlined,
        title: 'Smart Contract',
        path: '/smart-contract',
    },
    // (values.dashboardType === values.dashboardTypePublic || values.dashboardType === values.dashboardTypeKedaireka) && {
    //     type: 'single',
    //     icon: IconRouteOutlined,
    //     title: 'Trackability',
    //     path: '/trackability',
    // },
    // (values.dashboardType === values.dashboardTypePublic || values.dashboardType === values.dashboardTypeTmmin) && {
    //     type: 'single',
    //     icon: IconSecurityOutlined,
    //     title: 'Privacy',
    //     path: '/privacy',
    // },
    values.dashboardType !== values.dashboardTypePidi && {
        type: 'single',
        icon: IconHistoryOutlined,
        title: 'History',
        path: '/history',
    },
    [values.dashboardTypeTmmin, values.dashboardTypePublic].includes(values.dashboardType) && {
        type: 'single',
        icon: IconHistoryOutlined,
        title: 'Activity Log',
        path: '/activity-log',
    },
    values.dashboardType === values.dashboardTypePublic && {
        type: 'collection',
        title: 'Forms',
        icon: IconTextSnippetOutlined,
        path: '/forms',
        children: [
            {
                path: '/forms/items',
                title: 'Items',
            },
            {
                path: '/forms/actors',
                title: 'Actors',
            },
            {
                path: '/forms/touch-points',
                title: 'Touch Points',
            },
            {
                path: '/forms/workflows',
                title: 'Workflows',
            },
        ],
    },
    // values.dashboardType === values.dashboardTypePublic && {
    //     type: 'single',
    //     icon: IconChangeCircleOutlined,
    //     title: 'Transactions',
    //     path: '/transactions',
    // },
    // {
    //   type: 'single',
    //   icon: IconReceiptLongOutlined,
    //   title: 'Orders',
    //   path: '/orders',
    // },
];

drawerNavigationList = drawerNavigationList.filter((item) => item);

export default drawerNavigationList;
