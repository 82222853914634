// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext';
// LAYOUTS
import Authentication from 'layouts/Authentication/Authentication';
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
// UTILS
import { isObjectEmpty } from 'utils/validation';

function AuthenticationRoute(props) {
    const { children } = props;

    const { auth } = useContext(AllPagesContext);

    return (
        isObjectEmpty(auth) ?
            <Authentication>
                {children}
            </Authentication> :
            <Navigate
                replace
                to='/dashboard'
            />
    );
}

export default AuthenticationRoute;
